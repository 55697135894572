export const data = [
  {
    question: 'Popular_question1_',
    answer: 'Popular_answer1_',
  },
  {
    question: 'Popular_question7_',
    answer: 'Popular_answer7_',
  },
  {
    question: 'Popular_question2_',
    answer: 'Popular_answer2_',
  },
  {
    question: 'Popular_question6_',
    answer: 'Popular_answer6_',
  },
  {
    question: 'Popular_question3_',
    answer: 'Popular_answer3_',
  },
  {
    question: 'Popular_question4_',
    answer: 'Popular_answer4_',
  },
  {
    question: 'Popular_question5_',
    answer: 'Popular_answer5_',
  },
  {
    question: 'Popular_question8_',
    answer: 'Popular_answer8_',
  },
  {
    question: 'Popular_question9_',
    answer: 'Popular_answer9_',
  },
];
