import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import { 
  // FileObjectType, 
  SelectOption,
} from 'types';
import { CurrencyName } from 'appConstants';
import { 
  illustrationTgLightImage, 
  // loadIcon,
} from 'assets/images';
import { ModalContext } from 'context';
import { getTransactionsRatesData, withdrawTransactions } from 'store/transactions/actionCreators';
import { transactionsSelectors } from 'store/transactions/selectors';

import {
  Button,
  Checkbox,
  // Dropzone,
  Image,
  Input,
  Modal,
  Select,
  toastSuccess,
} from 'components';

import { LocaleKey } from 'utils';
import { authSelectors } from 'store/auth/selectors';
import { profileSelectors } from 'store/profile/selectors';
import { customStyles } from './customStyles';
import styles from './styles.module.scss';

export const WithdrawModal = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation('deposit');
  // const translation = useTranslation('support');
  const trans = useTranslation('main').t;
  const { closeJsxModal } = useContext(ModalContext);
  const countries = useSelector(authSelectors.getProp('country'));
  const userCountry = useSelector(profileSelectors.getProp('countryProfile'));
  const firstName = useSelector(profileSelectors.getProp('firstName'));
  const lastName = useSelector(profileSelectors.getProp('lastName'));
  const isCompany = useSelector(profileSelectors.getProp('isCompany'));
  const companyName = useSelector(profileSelectors.getProp('companyName'));
  const tin = useSelector(profileSelectors.getProp('tin'));
  
  const { rate, isLoading } = useSelector(transactionsSelectors.getState);

  const [amount, setAmount] = useState('');
  const [amountEuro, setAmountEuro] = useState('');
  const [feePercent, setFeePercent] = useState('');
  const [receivingCountry, setReceivingCountry] = useState('');
  // const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [fullName, setFullName] = useState('');
  const [iban, setIban] = useState('');
  const [swift, setSWIFT] = useState('');
  const [bankName, setBankName] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [correspondentAccount, setCorrespondentAccount] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [bankCity, setBankCity] = useState('');
  const [isCorresp, setIsCorresp] = useState(false);
  const [isEuro, setIsEuro] = useState(false);
  // const [file, setFile] = useState<FileObjectType | undefined>();
  const [check, setCheck] = useState(false);

  const isDisableBnt = !amount
  || Number(amount) < 100
  // || !amountEuro
  || !receivingCountry 
  // || !name 
  || !address 
  || !city 
  || !userCountry 
  || !iban 
  || !swift
  // || !file
  || !check;

  useEffect(() => {
    dispatch(getTransactionsRatesData());
  }, [dispatch]);

  useEffect(() => {
    if (isEuro) {
      const euroAmount = (Number(amount) * Number(rate)).toFixed(2);
      setAmountEuro(euroAmount);
  
      setFeePercent((Number(euroAmount) * 0.8).toFixed(2));
    } else {
      setFeePercent((Number(amount) * 0.8).toFixed(2));
    }
  }, [amount, rate, isEuro]);
  
  const onChangeAmount = useCallback((n: string) => {
    const numericAmount = Number(n);
    setAmount(n);
  
    if (isEuro) {
      const euroAmount = numericAmount * Number(rate);
      setAmountEuro(euroAmount.toFixed(2));
  
      setFeePercent((euroAmount * 0.8).toFixed(2));
    } else {
      setFeePercent((numericAmount * 0.8).toFixed(2));
    }
  }, [isEuro, rate]);
  
  const onChangeFeePercent = useCallback((n: string) => {
    const numericFee = Number(n);
    setFeePercent(n);
  
    if (isEuro) {
      const euroAmountBeforeFee = (numericFee * 1.25).toFixed(2);
      setAmountEuro(euroAmountBeforeFee);
  
      const usdAmount = (Number(euroAmountBeforeFee) / Number(rate)).toFixed(2);
      setAmount(usdAmount);
    } else {
      const amountBeforeFee = (numericFee * 1.25).toFixed(2);
      setAmount(amountBeforeFee);
  
      setAmountEuro((Number(amountBeforeFee) * Number(rate)).toFixed(2));
    }
  }, [isEuro, rate]);
  
  const onToggleAmountEuro = useCallback(() => {
    setIsEuro((prev) => {
      const newIsEuro = !prev;
  
      if (newIsEuro) {
        const euroAmount = (Number(amount) * Number(rate)).toFixed(2);
        setAmountEuro(euroAmount);
        setFeePercent((Number(euroAmount) * 0.8).toFixed(2));
      } else {
        setFeePercent((Number(amount) * 0.8).toFixed(2));
      }
  
      return newIsEuro;
    });
  }, [amount, rate]);

  const labelCheck = useMemo(() => (
    <Trans
      t={t}
      i18nKey="Payment_terms_"
    >
      I accept the terms &nbsp;
      <a
        href={`https://storage.googleapis.com/eclipse-cdn/files/payment-terms-${localStorage.getItem('lng') || LocaleKey.en}.pdf`}
        target="_blank"
        rel="noreferrer"
        className={styles.label_check}
      >
        Agency agreement
      </a>
    </Trans>
  ), [t]);

  const onCloseJsxModal = useCallback(() => {
    closeJsxModal();
  }, [closeJsxModal]);

  const onSuccess = useCallback(() => {
    closeJsxModal();
    toastSuccess(t('Operation_success_'));
  }, [closeJsxModal, t]);

  // const onChangeFile = useCallback((f?: FileObjectType) => {
  //   setFile(f);
  // }, [setFile]);

  const onChangeCountry = useCallback((value: SelectOption<string>) => {
    setReceivingCountry(value.label);
  }, []);

  const onWithdrawChange = useCallback(() => {
    dispatch(withdrawTransactions({
      currency: isEuro ? CurrencyName.EUR : CurrencyName.USD,
      amount: isEuro ? amountEuro : amount,
      receiving_country: receivingCountry,
      name: (firstName || lastName) ? `${firstName} ${lastName}` : fullName,
      address,
      city,
      country,
      iban,
      swift,
      bank_account_name: bankName,
      swift_code: swiftCode,
      bank_account_correspondent_account: correspondentAccount,
      bank_account_address: bankAddress,
      bank_account_city: bankCity,
      onCallback: onSuccess,
    }));
  }, [
    dispatch,
    isEuro,
    amount,
    amountEuro,
    receivingCountry,
    firstName,
    lastName,
    fullName,
    address,
    city,
    country,
    iban,
    swift,
    bankName,
    swiftCode,
    correspondentAccount,
    bankAddress,
    bankCity,
    onSuccess,
  ]);

  useEffect(() => {
    if (!isCorresp) {
      setBankName('');
      setSwiftCode('');
      setCorrespondentAccount('');
      setBankAddress('');
      setBankCity('');
      // setFile(undefined);
    }
  }, [
    isCorresp,
    setBankName,
    setSwiftCode,
    setCorrespondentAccount,
    setBankAddress,
    setBankCity,
    // setFile,
  ]);

  return (
    <Modal
      isOpen
      onClose={onCloseJsxModal}
      className={styles.deposit_container}
    >
      <Image
        url={illustrationTgLightImage}
        className={styles.deposit_img}
      />
      <div className={styles.deposit_title}>
        {t('Withdraw_funds_')}
      </div>
      <div className={cn(styles.deposit_input, styles.deposit_input_text)}>
        {t('Minimum_withdrawal_amount_')}
      </div>
      <div className={styles.deposit_attention_text}>
        {t('English_letters_only_')}
      </div>
      <Input
        isNumberOnly
        classNameContainer={styles.deposit_input}
        value={amount}
        onChangeValue={onChangeAmount}
        placeholder={`${t('Enter_amount_')}, $`}
        required
      />
      <Checkbox
        isChecked={isEuro}
        onChange={onToggleAmountEuro}
        label={t('Withdraw_in_euros_')}
        classNameContainer={styles.deposit_input}
      />
      {isEuro && (
        <Input
          classNameContainer={styles.deposit_input}
          value={amountEuro}
          disabled
          label={t('Amount_euro_rate_')}
        />
      )}
      <Input
        isNumberOnly
        classNameContainer={styles.deposit_input}
        value={feePercent}
        onChangeValue={onChangeFeePercent}
        placeholder="Net after -20%"
        required
      />
      {/* <Input
        classNameContainer={styles.deposit_input}
        value={receivingCountry}
        onChangeValue={setReceivingCountry}
        placeholder={t('Receiving_country_')}
        isWithdraw
      /> */}
      <Select
        options={countries}
        onChange={onChangeCountry}
        value={receivingCountry ? { value: receivingCountry, label: receivingCountry } : null}
        style={{ ...customStyles }}
        placeholder={t('Receiving_country_')}
        isSearchable
      />
      <Input
        classNameContainer={styles.deposit_input}
        value={(firstName || lastName) ? `${firstName} ${lastName}` : fullName}
        onChangeValue={setFullName}
        placeholder={t('Full_name_')}
        isWithdraw
      />
      {isCompany && (
      <Input
        classNameContainer={styles.deposit_input}
        value={companyName}
        isWithdraw
      />
      )}
      {isCompany && (
      <Input
        classNameContainer={styles.deposit_input}
        value={tin}
        placeholder={t('TIN_')}
        isWithdraw
      />
      )}
      <Input
        classNameContainer={styles.deposit_input}
        value={address}
        onChangeValue={setAddress}
        placeholder={t('Address_')}
        isWithdraw
      />
      <Input
        classNameContainer={styles.deposit_input}
        value={city}
        onChangeValue={setCity}
        placeholder={t('City_')}
        isWithdraw
      />
      <Input
        classNameContainer={styles.deposit_input}
        value={userCountry}
        onChangeValue={setCountry}
        placeholder={t('Country_')}
        isWithdraw
      />
      <Input
        classNameContainer={styles.deposit_input}
        value={iban}
        onChangeValue={setIban}
        placeholder={t('IBAN_')}
        isWithdraw
      />
      <Input
        classNameContainer={styles.deposit_input}
        value={swift}
        onChangeValue={setSWIFT}
        placeholder={t('SWIFT_')}
        isWithdraw
      />
      <Checkbox
        isChecked={isCorresp}
        onChange={setIsCorresp}
        label={t('Correspondent_account_')}
        classNameContainer={styles.deposit_input}
      />
      {isCorresp && (
        <>
          <Input
            classNameContainer={styles.deposit_input}
            value={bankName}
            onChangeValue={setBankName}
            placeholder={t('Bank_name_')}
            isWithdraw
          />
          <Input
            classNameContainer={styles.deposit_input}
            value={swiftCode}
            onChangeValue={setSwiftCode}
            placeholder={t('SWIFT_code_')}
            isWithdraw
          />
          <Input
            classNameContainer={styles.deposit_input}
            value={correspondentAccount}
            onChangeValue={setCorrespondentAccount}
            placeholder={t('Correspondent_account_')}
            isWithdraw
          />
          <Input
            classNameContainer={styles.deposit_input}
            value={bankAddress}
            onChangeValue={setBankAddress}
            placeholder={t('Bank_address_')}
            isWithdraw
          />
          <Input
            classNameContainer={styles.deposit_input}
            value={bankCity}
            onChangeValue={setBankCity}
            placeholder={t('City_')}
            isWithdraw
          />
        </>
      )}
      {/* <Dropzone
        data={file}
        onChange={onChangeFile}
        isTicket
        textInfo={file?.path || translation.t('Choose_file_or_drag_here_')}
        classNameContainer={styles.dropzone_avatar_ticket}
        textBtn={(
          <Image
            url={loadIcon}
            className={styles.ticket_btn}
          />
                  )}
      />
      <div className={styles.ticket_form_drop_text}>
        {translation.t('Supported_formats_')}
      </div> */}
      <Checkbox
        isChecked={check}
        onChange={setCheck}
        label={labelCheck}
      />
      <Button
        disabled={isDisableBnt}
        onClick={onWithdrawChange}
        isFullWidth
        isBigHeight
        isLoading={isLoading}
        className={styles.withdraw_button}
      >
        {trans('Withdraw_')}
      </Button>
    </Modal>
  );
});
