import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { dateFormat } from 'utils';
import { statusSelectors } from 'store/status/selectors';

import {
  TitleText,
} from 'components';

import styles from './styles.module.scss';

interface ITabContentSeller {
  data: {
    title: string;
    id: number,
    name: string,
    sales: number,
    isNoStatus?: boolean,
  }
}

export const TabContentSeller:FC<ITabContentSeller> = ({
  data,
}) => {
  const { t } = useTranslation('status');
  const trans = useTranslation('main').t;
  const statistic = useSelector(statusSelectors.getProp('statisticData'));

  return (
    <div className={styles.seller}>
      <div className={styles.seller_top}>
        <TitleText
          text={trans(data.title)}
          className={styles.seller_top_title}
        />
        <div className={styles.seller_top_info}>
          {statistic?.next_update_date && (
            t('Updated_once_every_', { time: dateFormat(statistic?.next_update_date, 'dd.MM.yy') })
          )}
        </div>
      </div>
      <div className={styles.seller_content}>
        <div>
          <div className={styles.seller_title}>
            {t('Status_conditions_')}
            :
          </div>
          <ul className={styles.seller_ul}>
            <li>{t('Have_purchased_annual_license_')}</li>
            <li>
              {t(data?.isNoStatus ? 'Personal_sales_' : 'Personal_sales2_', { amount: 100 })}
            </li>
            <li>
              {t(data?.isNoStatus ? 'Team_license_sales_' : 'Team_license_sales2_', { amount: data.sales })}
            </li>
          </ul>
        </div>
        <div className={styles.seller_right}>
          <div className={styles.seller_right_subtitle}>
            <span>{t('Attention_')}</span> 
            <p>{t('Reward_')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
